import { TQueryBookInfo, TPostBookInfo, TPutBookInfo } from '@src/modules'
import { TRemoveMany } from '@src/configs/interface.config'

import { request } from './config'

export const getListBookInfo = (params: TQueryBookInfo, token?: string) =>
  request({ url: 'book-info', method: 'GET', params }, { token })
export const createBookInfo = (data: TPostBookInfo) => request({ url: 'book-info', method: 'POST', data })
export const getBookInfoById = (id: string, token?: string) =>
  request({ url: `book-info/${id}`, method: 'GET' }, { token })
export const putBookInfoById = (id: string, data: TPutBookInfo) =>
  request({ url: `book-info/${id}`, method: 'PUT', data })
export const removeBookInfoById = (id: string) => request({ url: `book-info/${id}`, method: 'DELETE' })
export const removeBookInfoByIds = (data: TRemoveMany) =>
  request({ url: 'book-info/delete-many', method: 'POST', data })
