import { NError, NSuccess } from '@configs/const.config'
import { TResApi, TResApiErr, TResDataListApi } from '@configs/interface.config'
import { checkAuth } from '@src/libs/localStorage'
import { TAuthor, TQueryAuthor, TPutAuthor } from '@src/modules'
import { notification } from 'antd'
import { useMutation, useQuery, useInfiniteQuery } from 'react-query'

import { queryClient } from '..'
import { getListAuthor, getAuthorById, putAuthorById, createAuthor, removeAuthorById, removeAuthorByIds } from '../apis'
import { DETAIL_AUTHOR, LIST_AUTHOR, LIST_AUTHOR_INFINITE } from '../keys'

/**
 * @method useQueryListAuthor
 * @param {TQueryAuthor}params
 * @param {string}token
 * @returns
 */
export const useQueryListAuthor = (params: TQueryAuthor, token?: string) => {
  const accessToken = token || checkAuth()
  return useQuery<TResDataListApi<TAuthor[]>>(
    [LIST_AUTHOR, JSON.stringify(params)],
    () => getListAuthor(params, accessToken),
    { enabled: !!accessToken },
  )
}

/**
 * @method useQueryPackageById
 * @param {string}id
 * @param {string}token
 * @returns
 */
export const useQueryAuthorById = (id: string, token?: string) => {
  const accessToken = token || checkAuth()
  return useQuery<TResApi<TAuthor>, TResApiErr>([DETAIL_AUTHOR, id], () => getAuthorById(id, token), {
    enabled: !!accessToken && !!id,
  })
}

/**
 *
 * @method useMutationPutAuthorById
 * @returns
 */
export const useMutationPutAuthorById = () =>
  useMutation(({ id, data }: { id: string; data: TPutAuthor }) => putAuthorById(id, data), {
    onSuccess: async (res: TResApi, { id }) => {
      await queryClient.refetchQueries([LIST_AUTHOR])
      await queryClient.invalidateQueries([DETAIL_AUTHOR, id])
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      // [TODO] ...
      notification.error({ message: NError, description: error?.message })
    },
  })

/**
 *
 * @method useMutationCreateAuthor
 * @returns
 */
export const useMutationCreateAuthor = () =>
  useMutation(createAuthor, {
    onSuccess: async (res: TResApi<TAuthor>) => {
      await queryClient.refetchQueries([LIST_AUTHOR])
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      // [TODO] ...
      notification.error({ message: NError, description: error?.message })
    },
  })

/**
 *
 * @method useMutationRemoveAuthorById
 * @returns
 */
export const useMutationRemoveAuthorById = () =>
  useMutation((id: string) => removeAuthorById(id), {
    onSuccess: async (res: TResApi, id) => {
      await queryClient.refetchQueries([LIST_AUTHOR])
      queryClient.removeQueries([DETAIL_AUTHOR, id])
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      // [TODO] ...
      notification.error({ message: NError, description: error?.message })
    },
  })

/**
 *
 * @method useMutationRemoveAuthorByIds
 * @returns
 */
export const useMutationRemoveAuthorByIds = () =>
  useMutation(removeAuthorByIds, {
    onSuccess: (res: TResApi) => {
      queryClient.refetchQueries([LIST_AUTHOR]).then()
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      // [TODO] ...
      notification.error({ message: NError, description: error?.message })
    },
  })

export const useInfiniteListAuthor = (params: TQueryAuthor, token?: string) => {
  const accessToken = token || checkAuth()
  return useInfiniteQuery<TResDataListApi<TAuthor[]>>({
    queryKey: [LIST_AUTHOR_INFINITE, params],
    queryFn: ({ pageParam = 1 }) => getListAuthor({ ...params, page: pageParam }, accessToken),
    getNextPageParam: (lastPage) =>
      // eslint-disable-next-line no-unsafe-optional-chaining
      lastPage?.page < Math.ceil(lastPage?.total / lastPage?.limit) ? lastPage?.page + 1 : undefined,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  })
}
