import { NError, NSuccess } from '@configs/const.config'
import { TResApi, TResApiErr, TResDataListApi } from '@configs/interface.config'
import { checkAuth } from '@src/libs/localStorage'
import { TCollection, TQueryCollection, TPutCollection } from '@src/modules'
import { notification } from 'antd'
import { useMutation, useQuery } from 'react-query'

import { queryClient } from '..'
import {
  getListCollection,
  getCollectionById,
  putCollectionById,
  createCollection,
  removeCollectionById,
  removeCollectionByIds,
} from '../apis'
import { DETAIL_COLLECTION, LIST_COLLECTION } from '../keys'

/**
 * @method useQueryListCollection
 * @param {TQueryCollection}params
 * @param {string}token
 * @returns
 */
export const useQueryListCollection = (params: TQueryCollection, token?: string) => {
  const accessToken = token || checkAuth()
  return useQuery<TResDataListApi<TCollection[]>>(
    [LIST_COLLECTION, JSON.stringify(params)],
    () => getListCollection(params, accessToken),
    { enabled: !!accessToken },
  )
}

/**
 * @method useQueryCollectionById
 * @param {string}id
 * @param {string}token
 * @returns
 */
export const useQueryCollectionById = (id: string, token?: string) => {
  const accessToken = token || checkAuth()
  return useQuery<TResApi<TCollection>, TResApiErr>([DETAIL_COLLECTION, id], () => getCollectionById(id, token), {
    enabled: !!accessToken && !!id,
  })
}

/**
 *
 * @method useMutationPutCollectionById
 * @returns
 */
export const useMutationPutCollectionById = () =>
  useMutation(({ id, data }: { id: string; data: TPutCollection }) => putCollectionById(id, data), {
    onSuccess: async (res: TResApi, { id }) => {
      await queryClient.refetchQueries([LIST_COLLECTION])
      await queryClient.invalidateQueries([DETAIL_COLLECTION, id])
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      // [TODO] ...
      notification.error({ message: NError, description: error?.message })
    },
  })

/**
 *
 * @method useMutationCreateCollection
 * @returns
 */
export const useMutationCreateCollection = () =>
  useMutation(createCollection, {
    onSuccess: async (res: TResApi<TCollection>) => {
      await queryClient.refetchQueries([LIST_COLLECTION])
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      // [TODO] ...
      notification.error({ message: NError, description: error?.message })
    },
  })

/**
 *
 * @method useMutationRemoveCollectionById
 * @returns
 */
export const useMutationRemoveCollectionById = () =>
  useMutation((id: string) => removeCollectionById(id), {
    onSuccess: async (res: TResApi, id) => {
      await queryClient.refetchQueries([LIST_COLLECTION])
      queryClient.removeQueries([DETAIL_COLLECTION, id])
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      // [TODO] ...
      notification.error({ message: NError, description: error?.message })
    },
  })

/**
 *
 * @method useMutationRemoveCollectionByIds
 * @returns
 */
export const useMutationRemoveCollectionByIds = () =>
  useMutation(removeCollectionByIds, {
    onSuccess: (res: TResApi) => {
      queryClient.refetchQueries([LIST_COLLECTION]).then()
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      // [TODO] ...
      notification.error({ message: NError, description: error?.message })
    },
  })
