import { TResApi, TResDataListApi, TResApiErr } from '@configs/interface.config'
import { checkAuth } from '@src/libs/localStorage'
import { TPostOption, TOption } from '@src/modules'
import { useQuery, useMutation } from 'react-query'
import { NError, NSuccess } from '@configs/const.config'
import { notification } from 'antd'

import { queryClient } from '..'
import { getListOption, postOption } from '../apis'
import { LIST_OPTION } from '../keys'

/**
 * @method useQueryListOption
 * @param {TQueryEbook}params
 * @param {string}token
 * @returns
 */
export const useQueryListOption = (key: string[], token?: string) => {
  const accessToken = token || checkAuth()
  return useQuery<TResDataListApi<TOption[]>>(
    [LIST_OPTION, JSON.stringify(key)],
    () => getListOption(key, accessToken),
    { enabled: !!accessToken },
  )
}

/**
 *
 * @method useMutationPostOption
 * @returns
 */
export const useMutationPostOption = (key: string[]) =>
  useMutation((data: TPostOption) => postOption(data), {
    onSuccess: async (res: TResApi) => {
      await queryClient.refetchQueries([LIST_OPTION, JSON.stringify(key)])
      notification.success({ message: NSuccess, description: res?.message })
      queryClient.refetchQueries([LIST_OPTION, JSON.stringify(key)])
    },
    onError: (error: TResApiErr) => {
      // [TODO] ...
      notification.error({ message: NError, description: error?.message })
    },
  })
