import { TResApi, TResDataListApi, TResApiErr } from '@configs/interface.config'
import { checkAuth } from '@src/libs/localStorage'
import { TBookInfo, TQueryBookInfo, TPutBookInfo } from '@src/modules'
import { useQuery, useInfiniteQuery, useMutation } from 'react-query'
import { NError, NSuccess } from '@configs/const.config'
import { notification } from 'antd'

import { queryClient } from '..'
import { getListBookInfo, createBookInfo, getBookInfoById, putBookInfoById, removeBookInfoById } from '../apis'
import { LIST_BOOK_INFO, LIST_BOOK_INFO_INFINITE, DETAIL_BOOK_INFO } from '../keys'

/**
 * @method useQueryListBookInfo
 * @param {TQueryBookInfo}params
 * @param {string}token
 * @returns
 */
export const useQueryListBookInfo = (params: TQueryBookInfo, token?: string) => {
  const accessToken = token || checkAuth()
  return useQuery<TResDataListApi<TBookInfo[]>>(
    [LIST_BOOK_INFO, JSON.stringify(params)],
    () => getListBookInfo(params, accessToken),
    { enabled: !!accessToken },
  )
}

/**
 *
 * @method useMutationCreateBookInfo
 * @returns
 */
export const useMutationCreateBookInfo = () =>
  useMutation(createBookInfo, {
    onSuccess: async (res: TResApi<TBookInfo>) => {
      await queryClient.refetchQueries([LIST_BOOK_INFO])
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      // [TODO] ...
      notification.error({ message: NError, description: error?.message })
    },
  })

/**
 * @method useQueryBookInfoById
 * @param {string}id
 * @param {string}token
 * @returns
 */
export const useQueryBookInfoById = (id: string, token?: string) => {
  const accessToken = token || checkAuth()
  return useQuery<TResApi<TBookInfo>, TResApiErr>([DETAIL_BOOK_INFO, id], () => getBookInfoById(id, token), {
    enabled: !!accessToken && !!id,
  })
}

/**
 *
 * @method useMutationPutBookInfoById
 * @returns
 */
export const useMutationPutBookInfoById = () =>
  useMutation(({ id, data }: { id: string; data: TPutBookInfo }) => putBookInfoById(id, data), {
    onSuccess: async (res: TResApi, { id }) => {
      await queryClient.refetchQueries([LIST_BOOK_INFO])
      await queryClient.invalidateQueries([DETAIL_BOOK_INFO, id])
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      // [TODO] ...
      notification.error({ message: NError, description: error?.message })
    },
  })

/**
 *
 * @method useMutationRemoveBookInfoById
 * @returns
 */
export const useMutationRemoveBookInfoById = () =>
  useMutation((id: string) => removeBookInfoById(id), {
    onSuccess: async (res: TResApi, id) => {
      await queryClient.refetchQueries([LIST_BOOK_INFO])
      queryClient.removeQueries([DETAIL_BOOK_INFO, id])
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      // [TODO] ...
      notification.error({ message: NError, description: error?.message })
    },
  })

export const useInfiniteListBookInfo = (params: TQueryBookInfo, token?: string) => {
  const accessToken = token || checkAuth()
  return useInfiniteQuery<TResDataListApi<TBookInfo[]>>({
    queryKey: [LIST_BOOK_INFO_INFINITE, params],
    queryFn: ({ pageParam = 1 }) => getListBookInfo({ ...params, page: pageParam }, accessToken),
    getNextPageParam: (lastPage) =>
      // eslint-disable-next-line no-unsafe-optional-chaining
      lastPage?.page < Math.ceil(lastPage?.total / lastPage?.limit) ? lastPage?.page + 1 : undefined,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  })
}
