import { TResApi, TResDataListApi, TResApiErr } from '@configs/interface.config'
import { checkAuth } from '@src/libs/localStorage'
import { TEbook, TQueryEbook, TPutEbook } from '@src/modules'
import { useQuery, useInfiniteQuery, useMutation } from 'react-query'
import { NError, NSuccess } from '@configs/const.config'
import { notification } from 'antd'

import { queryClient } from '..'
import { getListEbook, createEbook, getEbookById, putEbookById, removeEbookById } from '../apis'
import { LIST_EBOOK, LIST_EBOOK_INFINITE, DETAIL_BOOK_INFO, LIST_BOOK_INFO } from '../keys'

/**
 * @method useQueryListEbook
 * @param {TQueryEbook}params
 * @param {string}token
 * @returns
 */
export const useQueryListEbook = (params: TQueryEbook, token?: string) => {
  const accessToken = token || checkAuth()
  return useQuery<TResDataListApi<TEbook[]>>(
    [LIST_EBOOK, JSON.stringify(params)],
    () => getListEbook(params, accessToken),
    { enabled: !!accessToken },
  )
}

/**
 *
 * @method useMutationCreateEbook
 * @returns
 */
export const useMutationCreateEbook = (id: string) =>
  useMutation(createEbook, {
    onSuccess: async (res: TResApi<TEbook>) => {
      await queryClient.invalidateQueries([DETAIL_BOOK_INFO, id])
      notification.success({ message: NSuccess, description: res?.message })
      queryClient.refetchQueries([DETAIL_BOOK_INFO, id])
    },
    onError: (error: TResApiErr) => {
      // [TODO] ...
      notification.error({ message: NError, description: error?.message })
    },
  })

/**
 * @method useQueryEbookById
 * @param {string}id
 * @param {string}token
 * @returns
 */
export const useQueryEbookById = (id: string, token?: string) => {
  const accessToken = token || checkAuth()
  return useQuery<TResApi<TEbook>, TResApiErr>([DETAIL_BOOK_INFO, id], () => getEbookById(id, token), {
    enabled: !!accessToken && !!id,
  })
}

/**
 *
 * @method useMutationPutEbookById
 * @returns
 */
export const useMutationPutEbookById = () =>
  useMutation(({ id, data }: { id: string; data: TPutEbook }) => putEbookById(id, data), {
    onSuccess: async (res: TResApi, { id }) => {
      await queryClient.refetchQueries([LIST_BOOK_INFO])
      await queryClient.invalidateQueries([DETAIL_BOOK_INFO, id])
      notification.success({ message: NSuccess, description: res?.message })
      queryClient.refetchQueries([DETAIL_BOOK_INFO, id])
    },
    onError: (error: TResApiErr) => {
      // [TODO] ...
      notification.error({ message: NError, description: error?.message })
    },
  })

/**
 *
 * @method useMutationRemoveEbookById
 * @returns
 */
export const useMutationRemoveEbookById = (bookInfo: string) =>
  useMutation((id: string) => removeEbookById(id), {
    onSuccess: async (res: TResApi) => {
      await queryClient.invalidateQueries([DETAIL_BOOK_INFO, bookInfo])
      notification.success({ message: NSuccess, description: res?.message })
      queryClient.refetchQueries([DETAIL_BOOK_INFO, bookInfo])
    },
    onError: (error: TResApiErr) => {
      // [TODO] ...
      notification.error({ message: NError, description: error?.message })
    },
  })

export const useInfiniteListEbook = (params: TQueryEbook, token?: string) => {
  const accessToken = token || checkAuth()
  return useInfiniteQuery<TResDataListApi<TEbook[]>>({
    queryKey: [LIST_EBOOK_INFINITE, params],
    queryFn: ({ pageParam = 1 }) => getListEbook({ ...params, page: pageParam }, accessToken),
    getNextPageParam: (lastPage) =>
      // eslint-disable-next-line no-unsafe-optional-chaining
      lastPage?.page < Math.ceil(lastPage?.total / lastPage?.limit) ? lastPage?.page + 1 : undefined,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  })
}
