import { EMediaSystem } from '@src/modules'
import placeholderImage from '@assets/placeholder-image.jpeg'

/* eslint-disable no-param-reassign */
export const removeAccents = (str: string) => {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i')
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
  str = str.replace(/đ/g, 'd')
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A')
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E')
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I')
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O')
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U')
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y')
  str = str.replace(/Đ/g, 'D')
  return str
}

export const capitalize = (text: string) => text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()

export const convertToSlug = (text: string) => {
  const a = removeAccents(text)
  return a
    .toLowerCase()
    .replace(/[^\w ]+/g, '')
    .replace(/ +/g, '-')
}

export function formatNumberWithDot(n?: number) {
  return n ? n.toString().replace(/\B(?!\.\d*)(?=(\d{3})+(?!\d))/g, '.') : 'N/A'
}

type TNodeInTree<T> = T & { _id: string; disabled?: boolean; disableCheckbox?: boolean } & {
  children: TNodeInTree<T>[]
}
export function removeChildOfNodeIdInTree<T>(tree: TNodeInTree<T>[], idRemoveChildren: string) {
  return [...tree].map((node): TNodeInTree<T> => {
    const newNode = { ...node }
    const children = newNode?.children ? [...newNode.children] : []
    if (newNode._id === idRemoveChildren) {
      newNode.children = []
      newNode.disabled = true
      newNode.disableCheckbox = true
    } else if (children && children?.length > 0) {
      removeChildOfNodeIdInTree(children, idRemoveChildren)
    }

    return { ...newNode }
  })
}
export function removeNodeInTree<T>(tree: TNodeInTree<T>[], idNode: string) {
  return [...tree].reduce<TNodeInTree<T>[]>((acc, node) => {
    const newNode = { ...node }
    const children = newNode?.children ? [...newNode.children] : []
    if (newNode?._id !== idNode) {
      if (children && children?.length > 0) newNode.children = removeNodeInTree(children, idNode)
      acc.push(newNode)
    }

    return acc
  }, [])
}

export function fileLocation(
  location?: string,
  system: EMediaSystem = EMediaSystem.S3,
  placeholder = placeholderImage,
) {
  if (!location) return placeholder
  switch (system) {
    case EMediaSystem.S3:
      return location
    case EMediaSystem.SERVER:
      return `${process.env.MEDIA_ENDPOINT}${location}`
    default:
      return placeholder
  }
}
