import { TResApi, TResDataListApi, TResApiErr } from '@configs/interface.config'
import { checkAuth } from '@src/libs/localStorage'
import {
  TAudioBook,
  TQueryAudioBook,
  TAudioChapter,
  TPutAudioBook,
  TPutAudioChapter,
  TQueryAudioChapter,
} from '@src/modules'
import { useQuery, useInfiniteQuery, useMutation } from 'react-query'
import { NError, NSuccess } from '@configs/const.config'
import { notification } from 'antd'

import { queryClient } from '..'
import {
  getListAudioBook,
  createAudioBook,
  getEbookById,
  createAudioChapter,
  putAudioBookById,
  putAudioChapter,
  getListAudioChapter,
  removeAudioChapterById,
  removeAudioBookById,
  removeBookInfoByIds,
} from '../apis'
import { LIST_EBOOK, LIST_EBOOK_INFINITE, DETAIL_BOOK_INFO, LIST_BOOK_INFO, LIST_AUDIO_CHAPTER } from '../keys'

/**
 * @method useQueryListAudioBook
 * @param {TQueryAudioBook}params
 * @param {string}token
 * @returns
 */
export const useQueryListAudioBook = (params: TQueryAudioBook, token?: string) => {
  const accessToken = token || checkAuth()
  return useQuery<TResDataListApi<TAudioBook[]>>(
    [LIST_EBOOK, JSON.stringify(params)],
    () => getListAudioBook(params, accessToken),
    { enabled: !!accessToken },
  )
}

/**
 *
 * @method useMutationCreateAudioBook
 * @returns
 */
export const useMutationCreateAudioBook = (id?: string) =>
  useMutation(createAudioBook, {
    onSuccess: async (res: TResApi<TAudioBook>) => {
      await queryClient.invalidateQueries([DETAIL_BOOK_INFO, id])
      notification.success({ message: NSuccess, description: res?.message })
      return queryClient.refetchQueries([DETAIL_BOOK_INFO, id])
    },
    onError: (error: TResApiErr) => {
      // [TODO] ...
      notification.error({ message: NError, description: error?.message })
    },
  })

/**
 * @method useQueryAudiBookById
 * @param {string}id
 * @param {string}token
 * @returns
 */
export const useQueryAudiBookById = (id: string, token?: string) => {
  const accessToken = token || checkAuth()
  return useQuery<TResApi<TAudioBook>, TResApiErr>([DETAIL_BOOK_INFO, id], () => getEbookById(id, token), {
    enabled: !!accessToken && !!id,
  })
}

/**
 *
 * @method useMutationPutAudioBookById
 * @returns
 */
export const useMutationPutAudioBookById = () =>
  useMutation(({ id, data }: { id: string; data: TPutAudioBook }) => putAudioBookById(id, data), {
    onSuccess: async (res: TResApi, { id }) => {
      await queryClient.refetchQueries([LIST_BOOK_INFO])
      await queryClient.refetchQueries([LIST_EBOOK])
      await queryClient.invalidateQueries([DETAIL_BOOK_INFO, id])
      notification.success({ message: NSuccess, description: res?.message })
      queryClient.refetchQueries([DETAIL_BOOK_INFO, id])
    },
    onError: (error: TResApiErr) => {
      // [TODO] ...
      notification.error({ message: NError, description: error?.message })
    },
  })

/**
 *
 * @method useMutationRemoveAudioBookById
 * @returns
 */
export const useMutationRemoveAudioBookById = (bookInfo?: string) =>
  useMutation((id: string) => removeAudioBookById(id), {
    onSuccess: async (res: TResApi, id) => {
      await queryClient.invalidateQueries([DETAIL_BOOK_INFO, bookInfo])
      queryClient.removeQueries([LIST_AUDIO_CHAPTER, { audioBook: id }])
      notification.success({ message: NSuccess, description: res?.message })
      queryClient.refetchQueries([DETAIL_BOOK_INFO, bookInfo])
    },
    onError: (error: TResApiErr) => {
      // [TODO] ...
      notification.error({ message: NError, description: error?.message })
    },
  })

export const useInfiniteAudioBookEbook = (params: TQueryAudioBook, token?: string) => {
  const accessToken = token || checkAuth()
  return useInfiniteQuery<TResDataListApi<TAudioBook[]>>({
    queryKey: [LIST_EBOOK_INFINITE, params],
    queryFn: ({ pageParam = 1 }) => getListAudioBook({ ...params, page: pageParam }, accessToken),
    getNextPageParam: (lastPage) =>
      // eslint-disable-next-line no-unsafe-optional-chaining
      lastPage?.page < Math.ceil(lastPage?.total / lastPage?.limit) ? lastPage?.page + 1 : undefined,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  })
}

export const useMutationRemoveBookInfoByIds = () =>
  useMutation(removeBookInfoByIds, {
    onSuccess: (res: TResApi) => {
      queryClient.refetchQueries([LIST_BOOK_INFO])
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      // [TODO] ...
      notification.error({ message: NError, description: error?.message })
    },
  })

// Book chapter
/**
 *
 * @method useMutationCreateAudioChapter
 * @returns
 */
export const useMutationCreateAudioChapter = (params?: any, id?: string) =>
  useMutation(createAudioChapter, {
    onSuccess: async (res: TResApi<TAudioChapter>) => {
      await queryClient.invalidateQueries([LIST_AUDIO_CHAPTER, JSON.stringify(params)])
      await queryClient.invalidateQueries([DETAIL_BOOK_INFO, id])
      notification.success({ message: NSuccess, description: res?.message })
      queryClient.refetchQueries([DETAIL_BOOK_INFO, id])
      queryClient.refetchQueries([LIST_AUDIO_CHAPTER, JSON.stringify(params)])
    },
    onError: (error: TResApiErr) => {
      // [TODO] ...
      notification.error({ message: NError, description: error?.message })
    },
  })

/**
 *
 * @method useMutationPutAudioChapterById
 * @returns
 */
export const useMutationPutAudioChapterById = (params?: any, id?: string) =>
  useMutation(({ id, data }: { id: string; data: TPutAudioChapter }) => putAudioChapter(id, data), {
    onSuccess: async (res: TResApi) => {
      await queryClient.invalidateQueries([LIST_AUDIO_CHAPTER, JSON.stringify(params)])
      await queryClient.invalidateQueries([DETAIL_BOOK_INFO, id])
      notification.success({ message: NSuccess, description: res?.message })
      queryClient.refetchQueries([DETAIL_BOOK_INFO, id])
      queryClient.refetchQueries([LIST_AUDIO_CHAPTER, JSON.stringify(params)])
    },
    onError: (error: TResApiErr) => {
      // [TODO] ...
      notification.error({ message: NError, description: error?.message })
    },
  })

/**
 * @method useQueryListAudioChapter
 * @param {TQueryAudioChapter}params
 * @param {string}token
 * @returns
 */
export const useQueryListAudioChapter = (params: TQueryAudioChapter, token?: string) => {
  const accessToken = token || checkAuth()
  return useQuery<TResDataListApi<TAudioChapter[]>>(
    [LIST_AUDIO_CHAPTER, JSON.stringify(params)],
    () => getListAudioChapter(params, accessToken),
    { enabled: !!accessToken },
  )
}

/**
 *
 * @method useMutationRemoveAudioChapterById
 * @returns
 */
export const useMutationRemoveAudioChapterById = (params: any, id?: string) =>
  useMutation((id: string) => removeAudioChapterById(id), {
    onSuccess: async (res: TResApi) => {
      await queryClient.invalidateQueries([DETAIL_BOOK_INFO, id])
      await queryClient.invalidateQueries([LIST_AUDIO_CHAPTER, JSON.stringify(params)])
      notification.success({ message: NSuccess, description: res?.message })
      queryClient.refetchQueries([DETAIL_BOOK_INFO, id])
      queryClient.refetchQueries([LIST_AUDIO_CHAPTER, JSON.stringify(params)])
    },
    onError: (error: TResApiErr) => {
      // [TODO] ...
      notification.error({ message: NError, description: error?.message })
    },
  })
