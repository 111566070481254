import { NSuccess } from '@configs/const.config'
import { TResApi, TResApiErr, TResDataListApi } from '@configs/interface.config'
import { checkAuth } from '@libs/localStorage'
import { TPatchUser, TPostUser, TQueryUser, TSetPassword, TUser } from '@src/modules'
import { notification } from 'antd'
import { useMutation, useQuery, useInfiniteQuery } from 'react-query'

import { queryClient } from '..'
import {
  getListUser,
  getTotalUser,
  getUserById,
  patchPasswordUserById,
  patchUserById,
  postUser,
  removeManyUserByIds,
  removeUserById,
} from '../apis'
import { DETAIL_USER, LIST_USER, TOTAL_USER, USER_PROFILE, LIST_MEMBER_INFINITE } from '../keys'

/**
 * @method useQueryListUser
 * @param params
 * @param token
 * @returns
 */
export const useQueryListUser = (params: TQueryUser, enabled?: boolean, token?: string) => {
  const accessToken = token || checkAuth()
  return useQuery<TResDataListApi<TUser[]>>(
    [LIST_USER, JSON.stringify(params)],
    () => getListUser(params, accessToken),
    {
      enabled: !!accessToken,
    },
  )
}

/**
 * @method useQueryUserById
 * @param id
 * @param token
 * @returns
 */
export const useQueryUserById = (id: string, token?: string) => {
  const accessToken = token || checkAuth()
  return useQuery<TResApi<TUser>>([DETAIL_USER, id], () => getUserById(id, accessToken), {
    enabled: !!accessToken && !!id,
  })
}

/**
 * @method useQueryUserTotal
 * @param {string}token
 * @returns
 */
export const useQueryUserTotal = (token?: string) => {
  const accessToken = token || checkAuth()
  return useQuery<TResApi<number>>([TOTAL_USER], () => getTotalUser(token), { enabled: !!accessToken })
}

/**
 * @method useMutationPostUser
 * @returns
 */
export const useMutationPostUser = () =>
  useMutation<TResApi<TUser>, TResApiErr, TPostUser>(postUser, {
    onSuccess: () => {
      queryClient.refetchQueries([LIST_USER])
    },
  })

/**
 * @method useMutationPatchUserById
 * @returns
 */
export const useMutationPatchUserById = () =>
  useMutation(({ id, data }: { id: string; data: TPatchUser }) => patchUserById(id, data), {
    onSuccess: (res: TResApi, { id }) => {
      queryClient.refetchQueries([LIST_USER])
      queryClient.invalidateQueries([DETAIL_USER, id])
      const profileData = queryClient.getQueryData<TResApi<TUser>>(USER_PROFILE)
      if (profileData?.data && profileData?.data?._id === id) {
        queryClient.refetchQueries([USER_PROFILE])
      }
      notification.success({ message: NSuccess, description: res?.message })
    },
  })

/**
 * @method useMutationSetPassword
 * @returns
 */
export const useMutationSetPassword = () =>
  useMutation(({ id, data }: { id: string; data: TSetPassword }) => patchPasswordUserById(id, data))

/**
 * @method useMutationRemoveUserById
 * @returns
 */
export const useMutationRemoveUserById = () =>
  useMutation(removeUserById, {
    onSuccess: (_res: TResApi, id) => {
      queryClient.refetchQueries([LIST_USER])
      queryClient.removeQueries([DETAIL_USER, id])
    },
  })

/**
 * @method useMutationRemoveManyUserByIds
 * @returns
 */
export const useMutationRemoveManyUserByIds = () =>
  useMutation(removeManyUserByIds, {
    onSuccess: (_res: TResApi) => {
      queryClient.refetchQueries([LIST_USER])
    },
  })
export const useInfiniteListUser = (params: TQueryUser, token?: string) => {
  const accessToken = token || checkAuth()
  return useInfiniteQuery<TResDataListApi<TUser[]>>({
    queryKey: [LIST_MEMBER_INFINITE, params],
    queryFn: ({ pageParam = 1 }) => getListUser({ ...params, page: pageParam }, accessToken),
    getNextPageParam: (lastPage) =>
      // eslint-disable-next-line no-unsafe-optional-chaining
      lastPage?.page < Math.ceil(lastPage?.total / lastPage?.limit) ? lastPage?.page + 1 : undefined,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  })
}
