import {
  TQueryAudioBook,
  TPutAudioBook,
  TPostAudioBook,
  TPostAudioChapter,
  TPutAudioChapter,
  TQueryAudioChapter,
} from '@src/modules'

import { request } from './config'

export const getListAudioBook = (params: TQueryAudioBook, token?: string) =>
  request({ url: 'audio-book', method: 'GET', params }, { token })
export const createAudioBook = (data: TPostAudioBook) => request({ url: 'audio-book', method: 'POST', data })
export const getAudioBookById = (id: string, token?: string) =>
  request({ url: `audio-book/${id}`, method: 'GET' }, { token })
export const putAudioBookById = (id: string, data: TPutAudioBook) =>
  request({ url: `audio-book/${id}`, method: 'PUT', data })
export const removeAudioBookById = (id: string) => request({ url: `audio-book/${id}`, method: 'DELETE' })

// Audio book chapter
export const createAudioChapter = (data: TPostAudioChapter) => request({ url: 'audio-chapter', method: 'POST', data })
export const putAudioChapter = (id: string, data: TPutAudioChapter) =>
  request({ url: `audio-chapter/${id}`, method: 'PUT', data })
export const getListAudioChapter = (params: TQueryAudioChapter, token?: string) =>
  request({ url: 'audio-chapter', method: 'GET', params }, { token })
export const removeAudioChapterById = (id: string) => request({ url: `audio-chapter/${id}`, method: 'DELETE' })
