import { TCreatePackage, TQueryPackage, TPatchPackage } from '@src/modules'
import { TRemoveMany } from '@src/configs/interface.config'

import { request } from './config'

export const getListPackage = (params: TQueryPackage, token?: string) =>
  request({ url: 'package', method: 'GET', params }, { token })
export const createPackage = (data: TCreatePackage) => request({ url: 'package', method: 'POST', data })
export const getPackageById = (id: string, token?: string) =>
  request({ url: `package/${id}`, method: 'GET' }, { token })
export const putPackageById = (id: string, data: TPatchPackage) =>
  request({ url: `package/${id}`, method: 'PUT', data })
export const removePackageById = (id: string) => request({ url: `package/${id}`, method: 'DELETE' })
export const removePackageByIds = (data: TRemoveMany) => request({ url: 'package/delete-many', method: 'POST', data })
