import { TQueryCollection, TPutCollection, TPostCollection } from '@src/modules'
import { TRemoveMany } from '@src/configs/interface.config'

import { request } from './config'

export const getListCollection = (params: TQueryCollection, token?: string) =>
  request({ url: 'collection', method: 'GET', params }, { token })
export const createCollection = (data: TPostCollection) => request({ url: 'collection', method: 'POST', data })
export const getCollectionById = (id: string, token?: string) =>
  request({ url: `collection/${id}`, method: 'GET' }, { token })
export const putCollectionById = (id: string, data: TPutCollection) =>
  request({ url: `collection/${id}`, method: 'PUT', data })
export const removeCollectionById = (id: string) => request({ url: `collection/${id}`, method: 'DELETE' })
export const removeCollectionByIds = (data: TRemoveMany) =>
  request({ url: 'collection/delete-many', method: 'POST', data })
