import { TQueryAuthor, TPutAuthor, TPostAuthor } from '@src/modules'
import { TRemoveMany } from '@src/configs/interface.config'

import { request } from './config'

export const getListAuthor = (params: TQueryAuthor, token?: string) =>
  request({ url: 'primary-author', method: 'GET', params }, { token })
export const createAuthor = (data: TPostAuthor) => request({ url: 'primary-author', method: 'POST', data })
export const getAuthorById = (id: string, token?: string) =>
  request({ url: `primary-author/${id}`, method: 'GET' }, { token })
export const putAuthorById = (id: string, data: TPutAuthor) =>
  request({ url: `primary-author/${id}`, method: 'PUT', data })
export const removeAuthorById = (id: string) => request({ url: `primary-author/${id}`, method: 'DELETE' })
export const removeAuthorByIds = (data: TRemoveMany) =>
  request({ url: 'primary-author/delete-many', method: 'POST', data })
