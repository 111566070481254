import lazyLoading from '@src/components/widgets/LazyLoading'
import { ACCESS } from '@src/configs/permission'
import React from 'react'
import { PathRouteProps } from 'react-router-dom'

export interface TRouteConfig extends PathRouteProps {
  Element: React.FC
  key: ACCESS
}

const Dashboard = lazyLoading(() => import('@pages/dashboard'))

const Role = lazyLoading(() => import('@pages/role'))

const User = lazyLoading(() => import('@pages/user'))
const CreateUser = lazyLoading(() => import('@pages/user/create'))
const DetailUser = lazyLoading(() => import('@pages/user/detail'))

const Media = lazyLoading(() => import('@pages/media'))

const Package = lazyLoading(() => import('@pages/package'))
const CreatePackage = lazyLoading(() => import('@pages/package/create'))
const DetailPackage = lazyLoading(() => import('@pages/package/detail'))

const Author = lazyLoading(() => import('@pages/author'))
const CreateAuthor = lazyLoading(() => import('@pages/author/create'))
const DetailAuthor = lazyLoading(() => import('@pages/author/detail'))

const NotificationTemplate = lazyLoading(() => import('@pages/notification-template'))
const CreateNotificationTemplate = lazyLoading(() => import('@pages/notification-template/create'))
const DetailNotificationTemplate = lazyLoading(() => import('@src/pages/notification-template/detail'))

const Collection = lazyLoading(() => import('@pages/collection'))
const CreateCollection = lazyLoading(() => import('@pages/collection/create'))
const DetailCollection = lazyLoading(() => import('@src/pages/collection/detail'))

const Book = lazyLoading(() => import('@pages/book'))
const CreateBook = lazyLoading(() => import('@pages/book/create'))
const DetailBook = lazyLoading(() => import('@pages/book/detail'))
const BookCategory = lazyLoading(() => import('@pages/book/category'))
const CreateBookCategory = lazyLoading(() => import('@pages/book/category/create'))
const DetailBookCategory = lazyLoading(() => import('@pages/book/category/detail'))

const Podcast = lazyLoading(() => import('@pages/podcast'))
const CreatePodcast = lazyLoading(() => import('@pages/podcast/create'))
const DetailPodcast = lazyLoading(() => import('@pages/podcast/detail'))
const PodcastCategory = lazyLoading(() => import('@pages/podcast/category'))
const CreatePodcastCategory = lazyLoading(() => import('@pages/podcast/category/create'))
const DetailPodcastCategory = lazyLoading(() => import('@pages/podcast/category/detail'))

const Comic = lazyLoading(() => import('@pages/comic'))
const CreateComic = lazyLoading(() => import('@pages/comic/create'))
const DetailComic = lazyLoading(() => import('@pages/comic/detail'))
const ComicCategory = lazyLoading(() => import('@pages/comic/category'))
const CreateComicCategory = lazyLoading(() => import('@pages/comic/category/create'))
const DetailComicCategory = lazyLoading(() => import('@pages/comic/category/detail'))

const Option = lazyLoading(() => import('@pages/option'))

const routeConfig: TRouteConfig[] = [
  {
    path: '/',
    Element: Dashboard,
    key: ACCESS.GENERAL,
  },

  // Role
  {
    path: '/role',
    Element: Role,
    key: ACCESS.LIST_ROLES,
  },
  {
    path: '/create-role',
    Element: Role,
    key: ACCESS.CREATE_ROLE,
  },
  {
    path: '/role/:id',
    Element: Role,
    key: ACCESS.VIEW_ROLE,
  },

  // User
  {
    path: '/user',
    Element: User,
    key: ACCESS.LIST_USERS,
  },
  {
    path: '/create-user',
    Element: CreateUser,
    key: ACCESS.CREATE_USER,
  },
  {
    path: '/user/:id',
    Element: DetailUser,
    key: ACCESS.VIEW_USER,
  },

  // Media
  {
    path: '/media',
    Element: Media,
    key: ACCESS.LIST_MEDIAS,
  },

  // Option
  {
    path: '/option',
    Element: Option,
    key: ACCESS.LIST_OPTION,
  },

  // Package
  {
    path: '/package',
    Element: Package,
    key: ACCESS.LIST_PACKAGE,
  },
  {
    path: '/create-package',
    Element: CreatePackage,
    key: ACCESS.CREATE_PACKAGE,
  },
  {
    path: '/package/:id',
    Element: DetailPackage,
    key: ACCESS.VIEW_PACKAGE,
  },

  // Author
  {
    path: '/author',
    Element: Author,
    key: ACCESS.LIST_PRIMARY_AUTHOR,
  },
  {
    path: '/create-author',
    Element: CreateAuthor,
    key: ACCESS.CREATE_PRIMARY_AUTHOR,
  },
  {
    path: '/author/:id',
    Element: DetailAuthor,
    key: ACCESS.VIEW_PRIMARY_AUTHOR,
  },
  // Notification template
  {
    path: '/notification-template',
    Element: NotificationTemplate,
    key: ACCESS.LIST_NOTIFICATION_TEMPLATE,
  },
  {
    path: '/create-notification-template',
    Element: CreateNotificationTemplate,
    key: ACCESS.CREATE_NOTIFICATION_TEMPLATE,
  },
  {
    path: '/notification-template/:id',
    Element: DetailNotificationTemplate,
    key: ACCESS.VIEW_NOTIFICATION_TEMPLATE,
  },
  // Collection
  {
    path: '/collection',
    Element: Collection,
    key: ACCESS.LIST_COLLECTIONS,
  },
  {
    path: '/create-collection',
    Element: CreateCollection,
    key: ACCESS.CREATE_COLLECTION,
  },
  {
    path: '/collection/:id',
    Element: DetailCollection,
    key: ACCESS.VIEW_COLLECTION,
  },

  // Book
  {
    path: '/book',
    Element: Book,
    key: ACCESS.LIST_BOOKS,
  },
  {
    path: '/create-book',
    Element: CreateBook,
    key: ACCESS.CREATE_BOOK,
  },
  {
    path: '/book/:id',
    Element: DetailBook,
    key: ACCESS.VIEW_BOOK,
  },
  {
    path: '/book-cat',
    Element: BookCategory,
    key: ACCESS.LIST_TAXONOMY,
  },
  {
    path: '/create-book-cat',
    Element: CreateBookCategory,
    key: ACCESS.CREATE_TAXONOMY,
  },
  {
    path: '/book-cat/:id',
    Element: DetailBookCategory,
    key: ACCESS.VIEW_TAXONOMY,
  },

  // Podcast
  {
    path: '/podcast',
    Element: Podcast,
    key: ACCESS.LIST_PODCASTS,
  },
  {
    path: '/create-podcast',
    Element: CreatePodcast,
    key: ACCESS.CREATE_PODCAST,
  },
  {
    path: '/podcast/:id',
    Element: DetailPodcast,
    key: ACCESS.VIEW_PODCAST,
  },
  {
    path: '/podcast-cat',
    Element: PodcastCategory,
    key: ACCESS.LIST_TAXONOMY,
  },
  {
    path: '/create-podcast-cat',
    Element: CreatePodcastCategory,
    key: ACCESS.CREATE_TAXONOMY,
  },
  {
    path: '/podcast-cat/:id',
    Element: DetailPodcastCategory,
    key: ACCESS.VIEW_TAXONOMY,
  },

  // Comic
  {
    path: '/comic',
    Element: Comic,
    key: ACCESS.LIST_PODCASTS,
  },
  {
    path: '/create-comic',
    Element: CreateComic,
    key: ACCESS.CREATE_PODCAST,
  },
  {
    path: '/comic/:id',
    Element: DetailComic,
    key: ACCESS.VIEW_PODCAST,
  },
  {
    path: '/comic-cat',
    Element: ComicCategory,
    key: ACCESS.LIST_TAXONOMY,
  },
  {
    path: '/create-comic-cat',
    Element: CreateComicCategory,
    key: ACCESS.CREATE_TAXONOMY,
  },
  {
    path: '/comic-cat/:id',
    Element: DetailComicCategory,
    key: ACCESS.VIEW_TAXONOMY,
  },

  // Transaction
  // {
  //   path: '/package-transaction',
  //   Element: PackageTransaction,
  //   key: ACCESS.LIST_TAXONOMY,
  // },
  // {
  //   path: '/package-transaction/:d',
  //   Element: DetailPackageTransaction,
  //   key: ACCESS.LIST_TAXONOMY,
  // },
  // {
  //   path: '/book-transaction',
  //   Element: BookTransaction,
  //   key: ACCESS.LIST_TAXONOMY,
  // },
  // {
  //   path: '/book-transaction/:id',
  //   Element: DetailBookTransaction,
  //   key: ACCESS.LIST_TAXONOMY,
  // },
]

export default routeConfig
