import { TQueryEbook, TPutEbook } from '@src/modules'

import { request } from './config'

export const getListEbook = (params: TQueryEbook, token?: string) =>
  request({ url: 'ebook', method: 'GET', params }, { token })
export const createEbook = (data: FormData) =>
  request({
    url: 'ebook',
    method: 'POST',
    data,
    headers: {
      Accept: 'application/xml, text/plain, text/html, */*',
      'Content-Type': 'multipart/form-data, application/x-www-form-urlencoded;charset=utf-8',
    },
    transformRequest: [() => data],
  })
export const getEbookById = (id: string, token?: string) => request({ url: `ebook/${id}`, method: 'GET' }, { token })
export const putEbookById = (id: string, data: TPutEbook) => request({ url: `ebook/${id}`, method: 'PUT', data })
export const removeEbookById = (id: string) => request({ url: `ebook/${id}`, method: 'DELETE' })
