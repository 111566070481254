import { TResApi, TResDataListApi, TResApiErr } from '@configs/interface.config'
import { checkAuth } from '@src/libs/localStorage'
import {
  TPutPodcast,
  TQueryPodcast,
  TPodcast,
  TQueryPodcastChapter,
  TPodcastChapter,
  TPutPodcastChapter,
} from '@src/modules'
import { useQuery, useMutation } from 'react-query'
import { NError, NSuccess } from '@configs/const.config'
import { notification } from 'antd'

import { queryClient } from '..'
import {
  getListPodcast,
  createPodcast,
  getPodcastById,
  putPodcastById,
  removePodcastById,
  getPodcastChapterById,
  putPodcastChapterById,
  removePodcastChapterById,
  createPodcastChapter,
  getListPodcastChapter,
} from '../apis'
import { LIST_PODCAST, DETAIL_PODCAST, LIST_PODCAST_CHAPTER, DETAIL_PODCAST_CHAPTER } from '../keys'

// Podcast
/**
 * @method useQueryListPodcast
 * @param {TQueryPodcast}params
 * @param {string}token
 * @returns
 */
export const useQueryListPodcast = (params: TQueryPodcast, token?: string) => {
  const accessToken = token || checkAuth()
  return useQuery<TResDataListApi<TPodcast[]>>(
    [LIST_PODCAST, JSON.stringify(params)],
    () => getListPodcast(params, accessToken),
    { enabled: !!accessToken },
  )
}

/**
 *
 * @method useMutationCreatePodcast
 * @returns
 */
export const useMutationCreatePodcast = () =>
  useMutation(createPodcast, {
    onSuccess: async (res: TResApi<TPodcast>) => {
      await queryClient.invalidateQueries([LIST_PODCAST])
      notification.success({ message: NSuccess, description: res?.message })
      queryClient.refetchQueries([LIST_PODCAST])
    },
    onError: (error: TResApiErr) => {
      notification.error({ message: NError, description: error?.message })
    },
  })

/**
 * @method useQueryPodcastById
 * @param {string}id
 * @param {string}token
 * @returns
 */
export const useQueryPodcastById = (id: string, token?: string) => {
  const accessToken = token || checkAuth()
  return useQuery<TResApi<TPodcast>, TResApiErr>([DETAIL_PODCAST, id], () => getPodcastById(id, token), {
    enabled: !!accessToken && !!id,
  })
}

/**
 *
 * @method useMutationPutPodcastById
 * @returns
 */
export const useMutationPutPodcastById = () =>
  useMutation(({ id, data }: { id: string; data: TPutPodcast }) => putPodcastById(id, data), {
    onSuccess: async (res: TResApi, { id }) => {
      await queryClient.refetchQueries([LIST_PODCAST])
      await queryClient.invalidateQueries([DETAIL_PODCAST, id])
      notification.success({ message: NSuccess, description: res?.message })
      queryClient.refetchQueries([DETAIL_PODCAST, id])
    },
    onError: (error: TResApiErr) => {
      // [TODO] ...
      notification.error({ message: NError, description: error?.message })
    },
  })

/**
 *
 * @method useMutationRemovePodcastById
 * @returns
 */
export const useMutationRemovePodcastById = () =>
  useMutation((id: string) => removePodcastById(id), {
    onSuccess: async (res: TResApi) => {
      await queryClient.invalidateQueries([LIST_PODCAST])
      notification.success({ message: NSuccess, description: res?.message })
      queryClient.refetchQueries([LIST_PODCAST])
    },
    onError: (error: TResApiErr) => {
      // [TODO] ...
      notification.error({ message: NError, description: error?.message })
    },
  })

// Podcast Chapter
/**
 * @method useQueryListPodcastChapter
 * @param {TQueryPodcastChapter}params
 * @param {string}token
 * @returns
 */
export const useQueryListPodcastChapter = (params: TQueryPodcastChapter, token?: string) => {
  const accessToken = token || checkAuth()
  return useQuery<TResDataListApi<TPodcastChapter[]>>(
    [LIST_PODCAST_CHAPTER, JSON.stringify(params)],
    () => getListPodcastChapter(params, accessToken),
    { enabled: !!accessToken },
  )
}

/**
 *
 * @method useMutationCreatePodcastChapter
 * @returns
 */
export const useMutationCreatePodcastChapter = () =>
  useMutation(createPodcastChapter, {
    onSuccess: async (res: TResApi<TPodcastChapter>) => {
      await queryClient.invalidateQueries([LIST_PODCAST_CHAPTER])
      notification.success({ message: NSuccess, description: res?.message })
      queryClient.refetchQueries([LIST_PODCAST_CHAPTER])
    },
    onError: (error: TResApiErr) => {
      notification.error({ message: NError, description: error?.message })
    },
  })

/**
 * @method useQueryPodcastChapterById
 * @param {string}id
 * @param {string}token
 * @returns
 */
export const useQueryPodcastByIdChapter = (id: string, token?: string) => {
  const accessToken = token || checkAuth()
  return useQuery<TResApi<TPodcastChapter>, TResApiErr>(
    [DETAIL_PODCAST_CHAPTER, id],
    () => getPodcastChapterById(id, token),
    {
      enabled: !!accessToken && !!id,
    },
  )
}

/**
 *
 * @method useMutationPutPodcastChapterById
 * @returns
 */
export const useMutationPutPodcastChapterById = () =>
  useMutation(({ id, data }: { id: string; data: TPutPodcastChapter }) => putPodcastChapterById(id, data), {
    onSuccess: async (res: TResApi, { id }) => {
      await queryClient.refetchQueries([LIST_PODCAST_CHAPTER])
      notification.success({ message: NSuccess, description: res?.message })
      queryClient.refetchQueries([LIST_PODCAST_CHAPTER])
    },
    onError: (error: TResApiErr) => {
      // [TODO] ...
      notification.error({ message: NError, description: error?.message })
    },
  })

/**
 *
 * @method useMutationRemovePodcastChapterById
 * @returns
 */
export const useMutationRemovePodcastChapterById = () =>
  useMutation((id: string) => removePodcastChapterById(id), {
    onSuccess: async (res: TResApi) => {
      await queryClient.invalidateQueries([LIST_PODCAST_CHAPTER])
      notification.success({ message: NSuccess, description: res?.message })
      queryClient.refetchQueries([LIST_PODCAST_CHAPTER])
    },
    onError: (error: TResApiErr) => {
      // [TODO] ...
      notification.error({ message: NError, description: error?.message })
    },
  })
