import {
  TQueryPodcast,
  TPutPodcast,
  TPostPodcast,
  TQueryPodcastChapter,
  TPostPodcastChapter,
  TPutPodcastChapter,
} from '@src/modules'
import { TRemoveMany } from '@src/configs/interface.config'

import { request } from './config'

// Podcast
export const getListPodcast = (params: TQueryPodcast, token?: string) =>
  request({ url: 'podcast', method: 'GET', params }, { token })
export const createPodcast = (data: TPostPodcast) => request({ url: 'podcast', method: 'POST', data })
export const getPodcastById = (id: string, token?: string) =>
  request({ url: `podcast/${id}`, method: 'GET' }, { token })
export const putPodcastById = (id: string, data: TPutPodcast) => request({ url: `podcast/${id}`, method: 'PUT', data })
export const removePodcastById = (id: string) => request({ url: `podcast/${id}`, method: 'DELETE' })
export const removePodcastByIds = (data: TRemoveMany) => request({ url: 'podcast/delete-many', method: 'POST', data })

// Podcast Chapter
export const getListPodcastChapter = (params: TQueryPodcastChapter, token?: string) =>
  request({ url: 'podcast-chapter', method: 'GET', params }, { token })
export const createPodcastChapter = (data: TPostPodcastChapter) =>
  request({ url: 'podcast-chapter', method: 'POST', data })
export const getPodcastChapterById = (id: string, token?: string) =>
  request({ url: `podcast-chapter/${id}`, method: 'GET' }, { token })
export const putPodcastChapterById = (id: string, data: TPutPodcastChapter) =>
  request({ url: `podcast-chapter/${id}`, method: 'PUT', data })
export const removePodcastChapterById = (id: string) => request({ url: `podcast-chapter/${id}`, method: 'DELETE' })
export const removePodcastChapterByIds = (data: TRemoveMany) =>
  request({ url: 'podcast-chapter/delete-many', method: 'POST', data })
