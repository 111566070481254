import { TResApi, TResApiErr, TResDataListApi } from '@src/configs/interface.config'
import { checkAuth } from '@src/libs/localStorage'
import { useMutation, useQuery } from 'react-query'
import { notification } from 'antd'
import { NError, NSuccess } from '@src/configs/const.config'
import { TNotificationTemplate, TPostNotificationTemplate, TQueryNotificationTemplate } from '@src/modules'
import { DETAIL_NOTIFICATION_TEMPLATE, LIST_NOTIFICATION_TEMPLATE } from '@queries/keys'
import {
  CreateNotificationTemplate,
  getLisNotificationTemplate,
  getNotificationTemplateById,
  patchNotificationTemplateById,
  postAndSendNotificationTemplate,
  removeNotificationTemplateById,
  removeNotificationTemplateByIds,
  sendNotificationTemplateById,
} from '@queries/apis'

import { queryClient } from '..'

export const useQueryListNotificationTemplate = (params: TQueryNotificationTemplate, token?: string) => {
  const accessToken = token || checkAuth()
  return useQuery<TResDataListApi<TNotificationTemplate[]>>(
    [LIST_NOTIFICATION_TEMPLATE, JSON.stringify(params)],
    () => getLisNotificationTemplate(params, accessToken),
    {
      enabled: !!accessToken,
    },
  )
}

export const useMutationRemoveNotificationTemplateById = () =>
  useMutation(removeNotificationTemplateById, {
    onSuccess: (res: TResApi, id) => {
      queryClient.refetchQueries([LIST_NOTIFICATION_TEMPLATE]).then()
      queryClient.removeQueries([DETAIL_NOTIFICATION_TEMPLATE, id])
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      // [TODO] ...
      notification.error({ message: NError, description: error?.message })
    },
  })
export const useQueryNotificationTemplateById = (id: string, token?: string) => {
  const accessToken = token || checkAuth()
  return useQuery<TResApi<TNotificationTemplate>>(
    [DETAIL_NOTIFICATION_TEMPLATE, id],
    () => getNotificationTemplateById(id, accessToken),
    {
      enabled: !!accessToken && !!id,
    },
  )
}
export const useMutationPostNotificationTemplate = () =>
  useMutation(CreateNotificationTemplate, {
    onSuccess: (res: TResApi<TNotificationTemplate>) => {
      queryClient.refetchQueries([LIST_NOTIFICATION_TEMPLATE]).then()
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      // [TODO] ...
      notification.error({ message: NError, description: error?.message })
    },
  })

export const useMutationPostAndSendNotificationTemplate = () =>
  useMutation(postAndSendNotificationTemplate, {
    onSuccess: (res: TResApi) => {
      queryClient.refetchQueries([LIST_NOTIFICATION_TEMPLATE]).then()
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      // [TODO] ...
      notification.error({ message: NError, description: error?.message })
    },
  })

export const useMutationPatchNotificationTemplateById = () =>
  useMutation(
    ({ id, data }: { id: string; data: Partial<TPostNotificationTemplate> }) => patchNotificationTemplateById(id, data),
    {
      onSuccess: (res: TResApi, { id }) => {
        queryClient.refetchQueries([LIST_NOTIFICATION_TEMPLATE]).then()
        queryClient.invalidateQueries([DETAIL_NOTIFICATION_TEMPLATE, id]).then()
        notification.success({ message: NSuccess, description: res?.message })
      },
      onError: (error: TResApiErr) => {
        // [TODO] ...
        notification.error({ message: NError, description: error?.message })
      },
    },
  )
export const useMutationRemoveNotificationTemplateByIds = () =>
  useMutation(removeNotificationTemplateByIds, {
    onSuccess: (res: TResApi) => {
      queryClient.refetchQueries([LIST_NOTIFICATION_TEMPLATE]).then()
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      // [TODO] ...
      notification.error({ message: NError, description: error?.message })
    },
  })
export const useMutationSendNotificationTemplateById = () =>
  useMutation(sendNotificationTemplateById, {
    onSuccess: (res: TResApi) => {
      queryClient.refetchQueries([LIST_NOTIFICATION_TEMPLATE]).then()
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      // [TODO] ...
      notification.error({ message: NError, description: error?.message })
    },
  })
