import {
  TQueryComicChapter,
  TPostComicChapter,
  TPutComicChapter,
  TQueryComic,
  TPostComic,
  TPutComic,
} from '@src/modules'
import { TRemoveMany } from '@src/configs/interface.config'

import { request } from './config'

// Comic
export const getListComic = (params: TQueryComic, token?: string) =>
  request({ url: 'comic', method: 'GET', params }, { token })
export const createComic = (data: TPostComic) => request({ url: 'comic', method: 'POST', data })
export const getComicById = (id: string, token?: string) => request({ url: `comic/${id}`, method: 'GET' }, { token })
export const putComicById = (id: string, data: TPutComic) => request({ url: `comic/${id}`, method: 'PUT', data })
export const removeComicById = (id: string) => request({ url: `comic/${id}`, method: 'DELETE' })
export const removeComicByIds = (data: TRemoveMany) => request({ url: 'comic/delete-many', method: 'POST', data })

// Comic Chapter
export const getListComicChapter = (params: TQueryComicChapter, token?: string) =>
  request({ url: 'comic-chapter', method: 'GET', params }, { token })
export const createComicChapter = (data: TPostComicChapter) => request({ url: 'comic-chapter', method: 'POST', data })
export const getComicChapterById = (id: string, token?: string) =>
  request({ url: `comic-chapter/${id}`, method: 'GET' }, { token })
export const putComicChapterById = (id: string, data: TPutComicChapter) =>
  request({ url: `comic-chapter/${id}`, method: 'PUT', data })
export const removeComicChapterById = (id: string) => request({ url: `comic-chapter/${id}`, method: 'DELETE' })
export const removeComicChapterByIds = (data: TRemoveMany) =>
  request({ url: 'comic-chapter/delete-many', method: 'POST', data })
