import { NError, NSuccess } from '@configs/const.config'
import { TResApi, TResApiErr, TResDataListApi } from '@configs/interface.config'
import { checkAuth } from '@src/libs/localStorage'
import { TPackage, TPatchPackage, TQueryPackage } from '@src/modules'
import { notification } from 'antd'
import { useMutation, useQuery } from 'react-query'

import { queryClient } from '..'
import {
  createPackage,
  getListPackage,
  getPackageById,
  putPackageById,
  removePackageById,
  removePackageByIds,
} from '../apis'
import { DETAIL_PACKAGE, LIST_PACKAGE } from '../keys'

/**
 *
 * @method useMutationCreatePackage
 * @returns
 */
export const useMutationCreatePackage = () =>
  useMutation(createPackage, {
    onSuccess: async (res: TResApi<TPackage>) => {
      await queryClient.refetchQueries([LIST_PACKAGE])
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      // [TODO] ...
      notification.error({ message: NError, description: error?.message })
    },
  })

/**
 * @method useQueryListPackage
 * @param {TQueryPackage}params
 * @param {string}token
 * @returns
 */
export const useQueryListPackage = (params: TQueryPackage, token?: string) => {
  const accessToken = token || checkAuth()
  return useQuery<TResDataListApi<TPackage[]>>(
    [LIST_PACKAGE, JSON.stringify(params)],
    () => getListPackage(params, accessToken),
    { enabled: !!accessToken },
  )
}

/**
 * @method useQueryPackageById
 * @param {string}id
 * @param {string}token
 * @returns
 */
export const useQueryPackageById = (id: string, token?: string) => {
  const accessToken = token || checkAuth()
  return useQuery<TResApi<TPackage>, TResApiErr>([DETAIL_PACKAGE, id], () => getPackageById(id, token), {
    enabled: !!accessToken && !!id,
  })
}

/**
 *
 * @method useMutationPutPackageById
 * @returns
 */
export const useMutationPutPackageById = () =>
  useMutation(({ id, data }: { id: string; data: TPatchPackage }) => putPackageById(id, data), {
    onSuccess: async (res: TResApi, { id }) => {
      await queryClient.refetchQueries([LIST_PACKAGE])
      await queryClient.invalidateQueries([DETAIL_PACKAGE, id])
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      // [TODO] ...
      notification.error({ message: NError, description: error?.message })
    },
  })

/**
 *
 * @method useMutationRemovePackageById
 * @returns
 */
export const useMutationRemovePackageById = () =>
  useMutation((id: string) => removePackageById(id), {
    onSuccess: async (res: TResApi, id) => {
      await queryClient.refetchQueries([LIST_PACKAGE])
      queryClient.removeQueries([DETAIL_PACKAGE, id])
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      // [TODO] ...
      notification.error({ message: NError, description: error?.message })
    },
  })

/**
 *
 * @method useMutationRemovePackageByIds
 * @returns
 */
export const useMutationRemovePackageByIds = () =>
  useMutation(removePackageByIds, {
    onSuccess: (res: TResApi) => {
      queryClient.refetchQueries([LIST_PACKAGE]).then()
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      // [TODO] ...
      notification.error({ message: NError, description: error?.message })
    },
  })
