import { TPatchNotificationTemplate, TPostNotificationTemplate, TQueryNotificationTemplate } from '@src/modules'
import { TRemoveMany } from '@src/configs/interface.config'

import { request } from './config'

export const CreateNotificationTemplate = (data: TPostNotificationTemplate) =>
  request({ url: 'notification-template', method: 'POST', data })
export const getLisNotificationTemplate = (params: TQueryNotificationTemplate, token: string) =>
  request({ url: 'notification-template', method: 'GET', params }, { token })
export const removeNotificationTemplateById = (id: string) =>
  request({ url: `notification-template/${id}`, method: 'DELETE' })
export const removeNotificationTemplateByIds = (data: TRemoveMany) =>
  request({ url: 'notification-template/delete-many', method: 'POST', data })
export const getNotificationTemplateById = (id: string, token: string) =>
  request({ url: `notification-template/${id}`, method: 'GET' }, { token })
export const patchNotificationTemplateById = (id: string, data: TPatchNotificationTemplate) =>
  request({ url: `notification-template/${id}`, method: 'PATCH', data })
export const sendNotificationTemplateById = (data: any) =>
  request({ url: 'notification-template/send', method: 'POST', data })
export const postAndSendNotificationTemplate = (data: TPostNotificationTemplate) =>
  request({ url: 'notification-template/create-send', method: 'POST', data })
