import { TResApi, TResDataListApi, TResApiErr } from '@configs/interface.config'
import { checkAuth } from '@src/libs/localStorage'
import { TPutComic, TQueryComic, TComic, TQueryComicChapter, TComicChapter, TPutComicChapter } from '@src/modules'
import { useQuery, useMutation } from 'react-query'
import { NError, NSuccess } from '@configs/const.config'
import { notification } from 'antd'

import { queryClient } from '..'
import {
  getListComic,
  createComic,
  getComicById,
  putComicById,
  removeComicById,
  getComicChapterById,
  putComicChapterById,
  removeComicChapterById,
  createComicChapter,
  getListComicChapter,
} from '../apis'
import { LIST_COMIC, DETAIL_COMIC, LIST_COMIC_CHAPTER, DETAIL_COMIC_CHAPTER } from '../keys'

// Comic
/**
 * @method useQueryListComic
 * @param {TQueryComic}params
 * @param {string}token
 * @returns
 */
export const useQueryListComic = (params: TQueryComic, token?: string) => {
  const accessToken = token || checkAuth()
  return useQuery<TResDataListApi<TComic[]>>(
    [LIST_COMIC, JSON.stringify(params)],
    () => getListComic(params, accessToken),
    { enabled: !!accessToken },
  )
}

/**
 *
 * @method useMutationCreateComic
 * @returns
 */
export const useMutationCreateComic = () =>
  useMutation(createComic, {
    onSuccess: async (res: TResApi<TComic>) => {
      await queryClient.invalidateQueries([LIST_COMIC])
      notification.success({ message: NSuccess, description: res?.message })
      queryClient.refetchQueries([LIST_COMIC])
    },
    onError: (error: TResApiErr) => {
      notification.error({ message: NError, description: error?.message })
    },
  })

/**
 * @method useQueryComicById
 * @param {string}id
 * @param {string}token
 * @returns
 */
export const useQueryComicById = (id: string, token?: string) => {
  const accessToken = token || checkAuth()
  return useQuery<TResApi<TComic>, TResApiErr>([DETAIL_COMIC, id], () => getComicById(id, token), {
    enabled: !!accessToken && !!id,
  })
}

/**
 *
 * @method useMutationPutComicById
 * @returns
 */
export const useMutationPutComicById = () =>
  useMutation(({ id, data }: { id: string; data: TPutComic }) => putComicById(id, data), {
    onSuccess: async (res: TResApi, { id }) => {
      await queryClient.refetchQueries([LIST_COMIC])
      await queryClient.invalidateQueries([DETAIL_COMIC, id])
      notification.success({ message: NSuccess, description: res?.message })
      queryClient.refetchQueries([DETAIL_COMIC, id])
    },
    onError: (error: TResApiErr) => {
      // [TODO] ...
      notification.error({ message: NError, description: error?.message })
    },
  })

/**
 *
 * @method useMutationRemoveComicById
 * @returns
 */
export const useMutationRemoveComicById = () =>
  useMutation((id: string) => removeComicById(id), {
    onSuccess: async (res: TResApi) => {
      await queryClient.invalidateQueries([LIST_COMIC])
      notification.success({ message: NSuccess, description: res?.message })
      queryClient.refetchQueries([LIST_COMIC])
    },
    onError: (error: TResApiErr) => {
      // [TODO] ...
      notification.error({ message: NError, description: error?.message })
    },
  })

// Comic Chapter
/**
 * @method useQueryListComicChapter
 * @param {TQueryComicChapter}params
 * @param {string}token
 * @returns
 */
export const useQueryListComicChapter = (params: TQueryComicChapter, token?: string) => {
  const accessToken = token || checkAuth()
  return useQuery<TResDataListApi<TComicChapter[]>>(
    [LIST_COMIC_CHAPTER, JSON.stringify(params)],
    () => getListComicChapter(params, accessToken),
    { enabled: !!accessToken },
  )
}

/**
 *
 * @method useMutationCreateComicChapter
 * @returns
 */
export const useMutationCreateComicChapter = () =>
  useMutation(createComicChapter, {
    onSuccess: async (res: TResApi<TComicChapter>) => {
      await queryClient.invalidateQueries([LIST_COMIC_CHAPTER])
      notification.success({ message: NSuccess, description: res?.message })
      queryClient.refetchQueries([LIST_COMIC_CHAPTER])
    },
    onError: (error: TResApiErr) => {
      notification.error({ message: NError, description: error?.message })
    },
  })

/**
 * @method useQueryComicChapterById
 * @param {string}id
 * @param {string}token
 * @returns
 */
export const useQueryComicByIdChapter = (id: string, token?: string) => {
  const accessToken = token || checkAuth()
  return useQuery<TResApi<TComicChapter>, TResApiErr>(
    [DETAIL_COMIC_CHAPTER, id],
    () => getComicChapterById(id, token),
    {
      enabled: !!accessToken && !!id,
    },
  )
}

/**
 *
 * @method useMutationPutComicChapterById
 * @returns
 */
export const useMutationPutComicChapterById = () =>
  useMutation(({ id, data }: { id: string; data: TPutComicChapter }) => putComicChapterById(id, data), {
    onSuccess: async (res: TResApi, { id }) => {
      await queryClient.refetchQueries([LIST_COMIC_CHAPTER])
      notification.success({ message: NSuccess, description: res?.message })
      queryClient.refetchQueries([LIST_COMIC_CHAPTER])
    },
    onError: (error: TResApiErr) => {
      // [TODO] ...
      notification.error({ message: NError, description: error?.message })
    },
  })

/**
 *
 * @method useMutationRemoveComicChapterById
 * @returns
 */
export const useMutationRemoveComicChapterById = () =>
  useMutation((id: string) => removeComicChapterById(id), {
    onSuccess: async (res: TResApi) => {
      await queryClient.invalidateQueries([LIST_COMIC_CHAPTER])
      notification.success({ message: NSuccess, description: res?.message })
      queryClient.refetchQueries([LIST_COMIC_CHAPTER])
    },
    onError: (error: TResApiErr) => {
      // [TODO] ...
      notification.error({ message: NError, description: error?.message })
    },
  })
